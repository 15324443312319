import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import MobileMenu from '../../components/MobileMenu';
import { ADMIN_SITE_LINK, BASE_URL } from '../../constant/constants';

const Header2 = (props) => {
  const [isSearchShow, setIsSearchShow] = useState(false);

  const searchHandler = () => {
    setIsSearchShow(!isSearchShow);
  };

  const ClickHandler = () => {
    window.scrollTo(10, 0);
  };

  const jwt_access_token = localStorage.getItem('jwt_access_token');
  const user_image = localStorage.getItem('user_image');

  return (
    <header id='header' className={props.topbarNone}>
      <div
        className={`wpo-site-header ${props.hclass}`}
        style={{ backgroundColor: '#192847' }}>
        <nav className='navigation navbar navbar-expand-lg navbar-light'>
          <div className='container-fluid'>
            <div className='row align-items-center'>
              <div className='col-lg-3 col-md-3 col-3 d-lg-none dl-block'>
                <div className='mobail-menu'>
                  <MobileMenu menu={props.menu} />
                </div>
              </div>
              <div className='col-lg-3 col-md-6 col-6'>
                <div className='navbar-header'>
                  <Link onClick={ClickHandler} className='navbar-brand' to={`/`}>
                    <img
                      style={{
                        width: '50px',
                        height: '50px',
                        marginLeft: '50px',
                      }}
                      src={`${BASE_URL}${props.Logo}`}
                      alt=''
                    />
                  </Link>
                </div>
              </div>
              <div className='col-lg-9 col-md-1 col-1 justify-right'>
                <div
                  id='navbar'
                  className='collapse navbar-collapse navigation-holder'>
                  <button className='menu-close'>
                    <i className='ti-close'></i>
                  </button>
                  {props.menu.map((n) => (
                    <ul className='nav navbar-nav mb-1 mb-lg-0' key={n.id}>
                      <li className='menu-item-has-children text-nowrap'>
                        {n.name === 'Home' ? (
                          <Link onClick={ClickHandler} to={`/`}>
                            {n.name === 'Home' ? n.name : ''}
                          </Link>
                        ) : n.children.length === 0 ? (
                          <Link
                            onClick={ClickHandler}
                            to={`/${n.name.toLowerCase()}/${n.id}`}
                            
                            
                            >
                            {n.name}
                          </Link>
                        ) : (
                          <Link>{n.name}  <i
                          className='fa-solid fa-angle-down'
                          style={{
                            color: 'white',
                            marginLeft: '3px',
                          }}></i></Link>
                        )}

                        <ul
                          className='sub-menu'
                          style={{
                            display: n.children.length === 0 ? 'none' : 'block',
                          }}>
                          {n.children.map((child) => (
                            <li key={child.id}>
                              {/* <Link
                                onClick={ClickHandler}

                                to={`/${child.name.toLowerCase()}/${child.id}`}>
                                {child.name}
                              </Link> */}


                              <Link
                          onClick={ClickHandler}
                          to={
                            child?.name == 'Surma Language Institute'
                              ? '/company/4/Surma Language Institute'
                              : child?.name == 'Surma Overseas Limited'
                              ? '/company/5/Surma%20Overseas%20Limited'
                              : child?.name == 'Surma International Limited'
                              ? '/company/1/Surma%20International%20Ltd'
                              : child?.name == 'Surma Training Institute'
                              ? '/company/7/Surma%20Overseas%20Training%20Center'
                              : `/${child.name.toLowerCase()}/${child.id}`
                          }
                        >
                          {child.name}
                        </Link>
                            </li>
                          ))}
                        </ul>
                      </li>
                    </ul>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </nav>
      </div>
    </header>
  );
};

export default Header2;

import React, { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import ImageViewer from 'react-simple-image-viewer';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import {
  BASE_URL,
  // GET_CONTENTS_BY_MENU_ID,
  GET_IMAGE_BY_MENU_ID,
} from '../../constant/constants';
import ImageList from '@material-ui/core/ImageList';
import ImageListItem from '@material-ui/core/ImageListItem';

const Profile = (props) => {
  const [contentsImage, setContentsImage] = useState([]);
  const { id } = useParams();

  useEffect(() => {
    fetch(`${GET_IMAGE_BY_MENU_ID}/${id || props.id}`)
      .then((response) => response.json())
      .then((data) => {
        setContentsImage(data.content_images);
      })
      .catch(() => {});
  }, [id, props.id]);

  const images = Object.entries(contentsImage).map(([key, value]) =>
    key === 'Profile' ? value.map((n) => `${BASE_URL}/media/${n}`) : ' '
  );

  const [currentImage, setCurrentImage] = useState(0);
  const [isViewerOpen, setIsViewerOpen] = useState(false);

  const openImageViewer = useCallback((index) => {
    setCurrentImage(index);
    setIsViewerOpen(true);
  }, []);

  const closeImageViewer = () => {
    setCurrentImage(0);
    setIsViewerOpen(false);
  };

  const isMobile = useMediaQuery('(max-width:600px)');
  const numCols = isMobile ? 1 : 3;

  useEffect(() => {
    if (isViewerOpen) {
      const styleElement = document.createElement('style');
      styleElement.innerHTML = `.styles-module_close__2I1sI { top: 95px !important;right: 80px !important;right: 80px !important;opacity: 1 !important; color:red !important; }`;
      document.head.appendChild(styleElement);

      return () => {
        document.head.removeChild(styleElement);
      };
    }
  }, [isViewerOpen]);

  return (
    <>
      <ImageList
        sx={{ width: 500 }}
        cols={numCols}
        rowHeight={500}
        style={{ margin: '50px' }}>
        {images.map((item, index) =>
          item.map((item, index) => (
            <ImageListItem key={item.img} gap={8}>
              <img
                style={{ height: '100%', width: '100%' }}
                src={`${item}`}
                srcSet={`${item}`}
                alt=''
                onClick={() => openImageViewer(index)}
                loading='lazy'
              />
            </ImageListItem>
          ))
        )}
      </ImageList>

      {isViewerOpen &&
        images.map((n) => (
          <ImageViewer
            key={n}
            src={n}
            currentIndex={currentImage}
            disableScroll={false}
            closeOnClickOutside={true}
            onClose={closeImageViewer}
          />
        ))}
    </>
  );
};

export default Profile;

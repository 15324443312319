import React from 'react';
import { Link } from 'react-router-dom';
import Marquee from 'react-fast-marquee';

const NoticesBanner = (props) => {
  const ClickHandler = () => {
    window.scrollTo(10, 0);
  };



  return (
    <section
      className=' justify-center mx-5 rounded  text-white text-center d-flex mx-auto py-1 px-2'
      style={{
        padding: '2px',
        marginTop: '20px',
        marginBottom: '20px',
        whiteSpace: 'nowrap',
        width: '90%',
        backgroundColor: '#192847',
        borderBottom: '2px solid gray',
        fontFamily: 'Roboto, sans-serif',
        fontSize: '15.4px',
        fontWeight: '300',
        lineHeight: '35px',
        wordSpacing: '0px',
      }}>
      <span style={{ fontWeight: '500' }}>NOTICES & EVENTS:</span>
      <Marquee
        pauseOnHover='true'
        style={{ cursor: 'pointer' }}
        className='ms-4'>
        {props?.data?.map((notice, index) => (
          <Link
            key={index}
            to={`/pdfShow/notice/${notice?.id}`}
            style={{ cursor: 'pointer' }}
            onClick={ClickHandler}
            className='text-white'>
            &nbsp;&nbsp;&nbsp;
            <i className='fa-solid fa-pen-to-square'></i>&nbsp;
            {notice?.title}
          </Link>
        ))}
      </Marquee>
    </section>
  );
};

export default NoticesBanner;

import React, { Fragment, useEffect, useState } from "react";
import Hero from "../../components/hero";

import Footer from "../../components/footer";
import Scrollbar from "../../components/scrollbar";
import PartnerSection from "../../components/PartnerSection";
import AboutS2 from "../../components/AboutS2";
import { GET_MENUS_ALL_NESTED, GET_NOTICES_WITHOUT_PAGINATION } from "../../constant/constants";
import ProjectSection4 from "../../components/ProjectSection4";
import Service5 from "../../components/Service5";
import NoticesBanner from "../../components/NoticesBanner";

const HomePage = () => {
  const [homeId, setHomeId] = useState();
  const [serviceId, setServiceId] = useState();
  const [allNotice, setAllNotice] = useState([]);
console.log(`bsjdfjf`,allNotice)
  useEffect(() => {

    fetch(`${GET_NOTICES_WITHOUT_PAGINATION}`)
    .then((response) => response.json())
    .then((data) => {
      setAllNotice(data?.notices)
    })
    .catch(() => {});
    fetch(`${GET_MENUS_ALL_NESTED}`)
      .then((response) => response.json())
      .then((data) => {
        data.menus.find((e) => (e.name === "Home" ? setHomeId(e.id) : null));
        data.menus.find((e) =>
          e.name === "Service" ? setServiceId(e.id) : null
        );
      })
      .catch(() => {});
  }, []);
  //console.log("nnnnnnn", homeId);

  return (
    <Fragment>
      <Hero />
      <NoticesBanner tClass={"wpo-testimonial-area-s3 "} data={allNotice}/>
      {/* <OurCompanies id={homeId} tClass={"wpo-testimonial-area-s3"} /> */}
      <AboutS2 id={homeId} />
      {/* <Service5 id={serviceId} /> */}
      <ProjectSection4 id={homeId} />
      <PartnerSection id={homeId} />
      <Scrollbar />
      <Footer />
    </Fragment>
  );
};
export default HomePage;
